<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Roles
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <portal to="secondNavbarRight">
      <b-button
        v-if="validateIfHasPermission([
          permissions.MODIFY_ROLES.value.dbValue
        ])"
        class="button is-primary force-right"
        @click="$router.push({ name: 'createRole' })"
      >
        New team role
      </b-button>
    </portal>
    <card
      external-card="material-card-content"
    >
      <div
        slot="body"
      >
        <div>
          <p class="has-margin-top">
            Create team roles and configure their permissions here.
          </p>
          <br>
          <div
            id="app"
          >
            <div class="columns is-multiline">
              <div
                v-for="role in roles"
                :key="role.id"
                :class="roles.length > 1 ? 'column is-half' : 'column is-full'"
              >
                <div class="box">
                  <header>
                    <p class="title is-4">
                      {{ role.name }}
                    </p>
                  </header>
                  <br>
                  <main class="subtitle">
                    <span>{{ role.usersWithRole }}</span>
                    <span>{{ role.usersWithRole === 1 ? ' user' : ' users' }}</span>
                  </main>
                  <footer class="force-right">
                    <b-button
                      type="is-light"
                      :icon-right="role.canEdit ? 'pencil' : 'eye'"
                      class="mr-2"
                      @click="$router.push(`/${$route.params.companyId}/editRole/${role.id}`)"
                    >
                      {{ role.canEdit ? 'Edit' : 'See' }}
                    </b-button>
                    <b-tooltip
                      multilined
                      type="is-dark"
                      position="is-bottom"
                      :active="role.isDefaultRole || !role.canEdit || isTheSameRole(role.id)"
                      :label="isTheSameRole(role.id)
                        ? 'You cannot delete this role because you belong to it'
                        : 'You cannot delete this role because it is a default role'"
                    >
                      <b-button
                        :disabled="role.isDefaultRole || !role.canEdit"
                        type="is-light"
                        icon-right="trash-can"
                        @click="openConfirmationModal(role)"
                      >
                        Delete
                      </b-button>
                    </b-tooltip>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import card from '@/components/cross/Card.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import ErrorMixin from '@/mixins/error';
import CompanyMixin from '@/mixins/company';
import RoleMixin from '@/mixins/roles';
import snackBarMessage from '@/helpers/snackBarMessage';
import vtulEnums from '../../../cross/index';

const { permissions } = vtulEnums.enum.roles;

export default {
  name: 'Roles',
  metaInfo: {
    title: 'Team Roles',
  },
  components: {
    card,
  },
  mixins: [ErrorMixin, CompanyMixin, RoleMixin],
  data() {
    return {
      permissions,
      limitRoles: 10,
      skipRoles: 0,
      total: 0,
      currentPage: this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1,
    };
  },
  computed: {
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    ...mapGetters('roles', { findRolesInStore: 'find' }),
    memberships() {
      const memberships = this.findMembershipsInStore({
        query: {
          companyId: this.currentCompany.id,
          isOwner: false,
        },
      }).data;

      return memberships;
    },
    roles() {
      const roles = this.findRolesInStore({
        query: {
          companyId: this.currentCompany.id,
        },
      }).data;
      const data = [{
        id: 'c32d8b45-92fe-44f6-8b61-42c2107dfe87',
        name: 'Owner',
        canEdit: false,
        usersWithRole: 1,
      }];
      if (roles) {
        roles.sort((x, y) => {
          if (x.name > y.name) {
            return 1;
          }
          if (x.name < y.name) {
            return -1;
          }
          return 0;
        });
        roles.forEach((role) => {
          const memberships = this.memberships
            .filter((membership) => !membership.isOwner && membership.roleId === role.id);
          data.push({
            id: role.id,
            name: role.name,
            isDefaultRole: role.isDefaultRole,
            usersWithRole: memberships.length,
            canEdit: !this.isTheSameRole(role.id),
          });
        });
      }
      return data;
    },
  },
  watch: {
    currentPage(newPage) {
      this.$router.replace(`/${this.$route.params.companyId}/roles/${newPage}`);
    },
  },
  created() {
    this.fetchRolesFromApi();
    this.fetchMembershipsFromApi();
  },
  methods: {
    ...mapActions('roles', { findRoles: 'find', removeRole: 'remove' }),
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    async fetchMembershipsFromApi(skip) {
      if (!this.currentCompany) return;
      let skipMemberships = skip;
      if (!skipMemberships) skipMemberships = 0;

      const { total, data } = await this.findMemberships({
        query: {
          $skip: skipMemberships,
          $limit: 50,
          companyId: this.currentCompany.id,
          isOwner: false,
        },
      });
      this.total = total;
      skipMemberships += data.length;
      if (skipMemberships < total && this.storedDevices.length !== total) {
        await this.fetchMembershipsFromApi(skipMemberships);
      } else {
        this.loading = true;
      }
    },
    async fetchRolesFromApi(skip) {
      if (!this.currentCompany) return;
      let skipRoles = skip;
      if (!skipRoles) skipRoles = 0;

      const { total, data } = await this.findRoles({
        query: {
          $skip: skipRoles,
          $limit: 50,
          companyId: this.currentCompany.id,
        },
      });
      this.total = total;
      skipRoles += data.length;
      if (skipRoles < total && this.storedDevices.length !== total) {
        await this.fetchRolesFromApi(skipRoles);
      } else {
        this.loading = true;
      }
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipRoles = (this.currentPage - 1) * this.limitRoles;
    },
    openConfirmationModal(role) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: 'Delete role',
          message: `Are you sure you want to delete the ${role.name} role?`,
          confirmText: 'Delete',
          onConfirm: () => this.deleteRole(role.id),
        },
      });
    },
    async deleteRole(roleId) {
      try {
        const role = await this.findRoles({ query: { id: roleId } });
        await this.removeRole([roleId, {
          query: {
            companyId: this.currentCompany.id,
          },
        }]);
        const user = this.$store.getters['auth/user'];
        const log = {
          user: user.email,
          action: `Role "${role.data[0].name}" deleted.`,
          companyId: this.currentCompany.id,
        };
        this.createAdminLog(log);
        this.fetchMembershipsFromApi();
        this.fetchRolesFromApi();
      } catch (error) {
        snackBarMessage.showError(error);
      }
    },
    isTheSameRole(id) {
      return this.currentRole.id === id && !this.currentMembership.isOwner;
    },
  },
};
</script>
<style scope>
  .has-margin-top{
    margin-top:0.7rem;
  }
</style>
